<div class=" inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">

                <!-- Title -->
                <div class="flex justify-between  pt-8 px-6">
                    <div class="text-4xl font-extrabold tracking-tight leading-none">
                        {{translateStrings.order.plural}}
                    </div>
                    <div class="mt-3 sm:mt-0 sm:ml-2">
                        <mat-button-toggle-group value="this-week" #ordersAmountWeekSelector="matButtonToggleGroup">
                            <mat-button-toggle value="last-week">Last Week</mat-button-toggle>
                            <mat-button-toggle value="this-week">This Week</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <!-- Header -->
                <div
                    class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between md:px-8 border-b pt-2 pb-4 px-6">
                    <!-- Loader -->
                    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
                        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                    </div>

                    <!-- Orders Chart and Summary -->
                    <div
                        class="sm:col-span-2 md:col-span-4 flex flex-col flex-auto bg-card rounded-2xl overflow-hidden">
                        <div class="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-6 w-full mt-8 sm:mt-4">
                            <!-- New vs. Closed -->
                            <div class="flex flex-col flex-auto">
                                <div class="flex flex-col flex-auto">
                                    <apx-chart #ordersChart class="flex-auto w-full h-20 hidden lg:block"
                                        [chart]="chartOrdersAmountIssues.chart"
                                        [colors]="chartOrdersAmountIssues.colors"
                                        [dataLabels]="chartOrdersAmountIssues.dataLabels"
                                        [grid]="chartOrdersAmountIssues.grid" [labels]="chartOrdersAmountIssues.labels"
                                        [legend]="chartOrdersAmountIssues.legend"
                                        [plotOptions]="chartOrdersAmountIssues.plotOptions"
                                        [series]="chartOrdersAmountIssues.series['last-6-months']"
                                        [states]="chartOrdersAmountIssues.states"
                                        [stroke]="chartOrdersAmountIssues.stroke"
                                        [tooltip]="chartOrdersAmountIssues.tooltip"
                                        [xaxis]="chartOrdersAmountIssues.xaxis"
                                        [yaxis]="chartOrdersAmountIssues.yaxis"></apx-chart>
                                </div>
                            </div>
                            <!-- Overview -->
                            <div class="flex flex-col">
                                <div class="flex-auto grid grid-cols-4 gap-4 mt-6">
                                    <!-- New issues -->
                                    <div
                                        class="col-span-2 flex flex-col items-center justify-center py-8 px-1 rounded-2xl bg-indigo-50 text-indigo-800 dark:bg-white dark:bg-opacity-5 dark:text-indigo-400">
                                        <div class="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                                            {{data.ordersAmount.overview[ordersAmountWeekSelector.value]['Waiting']}}
                                        </div>
                                        <div class="mt-1 text-sm sm:text-lg font-medium">In attesa</div>
                                    </div>
                                    <!-- Closed -->
                                    <div
                                        class="col-span-2 flex flex-col items-center justify-center py-8 px-1 rounded-2xl bg-green-50 text-green-800 dark:bg-white dark:bg-opacity-5 dark:text-green-500">
                                        <div class="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                                            {{data.ordersAmount.overview[ordersAmountWeekSelector.value]['Processed']}}
                                        </div>
                                        <div class="mt-1 text-sm sm:text-lg font-medium">Processati</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Main actions -->
                    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <!-- <div class="flex-auto">
                            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix
                                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input
                                    matInput
                                    [formControl]="searchInputControl"
                                    [autocomplete]="'off'"
                                    [placeholder]="translateStrings.order.search">
                            </mat-form-field>
                        </div> -->

                        <!-- Add order button -->
                        <!-- <button class="ml-4" mat-flat-button [color]="'primary'">
                            <mat-icon [svgIcon]="'heroicons_outline:document-duplicate'"></mat-icon>
                            <span class="ml-2 mr-1">{{ translateStrings.order.copyToNew }}</span>
                        </button> -->
                    </div>
                </div>

                <!-- Order list -->
                <div *ngIf="!isLoading">
                    <div *ngIf="!isLoadingError; else loadingerror" class="relative">
                        <ng-container *ngIf="filteredOrders?.length; else noOrders">
                            <ng-container *ngFor="let order of filteredOrders; let i = index; trackBy: trackByFn">
                                <!-- Group -->
                                <ng-container *ngIf="i === 0 || (order.dateMonth !== filteredOrders[i - 1].dateMonth)">
                                    <div
                                        class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-secondary bg-gray-50 dark:bg-gray-900">
                                        {{order.date | date: 'MMMM YY'}}
                                    </div>
                                </ng-container>
                                <!-- Order -->
                                <a class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b"
                                    *ngIf="order.orderId; else noLink"
                                    [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedOrder || selectedOrder.id !== order.id,
                                            'bg-primary-50 dark:bg-hover': selectedOrder && selectedOrder.id === order.id}"
                                    [routerLink]="[orderDetailPath, order.orderId]">
                                    <div class="min-w-0 ml-4">
                                        <div class="font-medium leading-5 truncate">{{order.name}}
                                            #<strong>{{order.id}}</strong></div>
                                        <div class="leading-5 truncate text-secondary">{{order.date | date: 'dd-MMM'}}
                                        </div>
                                    </div>
                                    <div
                                        class="ml-auto flex flex-row items-center py-0.5 px-3 align-middle rounded-full text-sm font-semibold dark:text-{{getStatusColor(order.status)}}-50 dark:bg-{{getStatusColor(order.status)}}-500 text-{{getStatusColor(order.status)}}-700 bg-{{getStatusColor(order.status)}}-100">
                                        <mat-icon *ngIf="getStatusIcon(order.status)"
                                            class="icon-size-4 mr-2 text-{{getStatusColor(order.status)}}-700"
                                            svgIcon="{{getStatusIcon(order.status)}}"></mat-icon>
                                        <div class="text-base">{{order.status | lowercase}}</div>
                                    </div>
                                </a>
                                <ng-template #noLink>
                                    <div class="z-20 flex items-center px-6 py-4 md:px-8 border-b ">
                                        <div class="min-w-0 ml-4">
                                            <div class="font-medium leading-5 truncate">{{order.name}}
                                                #<strong>{{order.id}}</strong></div>
                                            <div class="leading-5 truncate text-secondary">{{order.date | date:
                                                'dd-MMM'}}</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <!-- No orders -->
                        <ng-template #noOrders>
                            <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                                {{translateStrings.order.noSearchResults}}</div>
                        </ng-template>

                    </div>
                </div>
            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>

<ng-template #loadingerror>
    <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="w-2/6 h-1/6 mx-auto">
    <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
        {{translateStrings.generic.error503}}</div>
</ng-template>