import '../order.types';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TemplatePortal } from '@angular/cdk/portal';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsLocalStorageService } from '@gruuls-core/infrastructure/gruuls-local-storage-service';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { ORDERSTATUS, Order, OrderInfo } from '../order.types';
import { DOCUMENT } from '@angular/common';
import { GruulsAngularCartService } from '@gruuls-fe/services/gruuls-angular-cart.service';
import { OrderStatus } from 'app/beautycians/utils/orderStatus';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { MatDialog } from '@angular/material/dialog';
import { OrderConfirmationDialogComponent } from './order-confirmation-dialog.component';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { XlsUtils } from 'app/beautycians/utils/excel';
import _ from 'lodash';
import { BeautyciansUtils } from 'app/beautycians/utils/utils';
import { Organization } from '../../centri/centri.types';
import { Store } from 'app/beautycians/utils/dataTypes';

@Component({
    selector: 'order-details-supplier',
    templateUrl: './order-details-supplier.component.html',
    styles: [
        /* language=SCSS */
        // TODO: set correct width
        `
            .inventory-grid {
                grid-template-columns: 48px auto 112px 72px 72px 72px;

                @screen sm {
                    grid-template-columns: 48px auto 112px 112px;
                }

                @screen md {
                    grid-template-columns: 48px auto 120px 120px 72px 72px 72px;
                }

                @screen lg {
                    grid-template-columns: 48px auto 200px 200px 72px 72px 72px;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsSupplierComponent implements OnInit, OnDestroy {
    @ViewChild('addCommentForm') addCommentForm: NgForm;
    @ViewChild('contextToggle') contextToggle: MatSlideToggle;
    @ViewChild('commentInput') commentInput: ElementRef;
    @ViewChild('statusPanel') private _statusPanel: TemplateRef<any>;
    @ViewChild('statusPanelOrigin') private _statusPanelOrigin: ElementRef;

    comments: {}[];
    showRemove: boolean[] = [];
    isLoading: boolean = true;
    orderId: string;
    order: Order;
    currentOrder: {} = {};
    orderStatus: string[] = Object.keys(ORDERSTATUS);
    isSpecificLoading: boolean[] = [];
    canChange: boolean = false;
    defaultPaymentTerms: string;
    shippingStore: Store;
    legalAddress: Store;
    
    private ID_PAGAMENTI_CONTRATTO = [43, 44, 45, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58];
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _statusPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    translateStrings: any = { order: {}, user: {}, generic: {}, orderDetail: {}, product: {} };

    paymentConditions: any[];
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: GruulsAuthService,
        private _cartService: GruulsAngularCartService,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _translate: GruulsAngularTranslateService,
        private gruulsLocalStorage: GruulsLocalStorageService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    /**
     * On init
     */
    ngOnInit(): void {
        let myOrder$: Observable<Order>;

        this.paymentConditions = [
            { id: 12, label: "Bonifico Immediato" },
            { id: 27, label: "Bonifico 30 GG DFFM" },
            { id: 36, label: "Bonifico 60GG DFFM" },
            { id: 29, label: "Bonifico 30/60 GG DFFM" },
            { id: 22, label: "Bonifico 30/60/90 GG DFFM" },
            { id: 23, label: "Bonifico 30/60/90/120 GG DFFM" },
            { id: 28, label: "Bonifico 60/90 GG DFFM" },
            { id: 30, label: "Bonifico anticipato SC 3%" },
            { id: 40, label: "Compensazione su ritiro merce" },
        ];

        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            // Get the order details
            this.orderId = params.id;
            if (!this.orderId) {
                this.canChange = true;
                const currentOrder = this._cartService.getCurrentCart();
                Object.entries(currentOrder).forEach(([key, value]) => {
                    this.order.cart.products.push({ productId: key, quantity: Number(value), discount: 0 });
                });
                myOrder$ = of(this.order);
                this._changeDetectorRef.markForCheck();
            } else {
                myOrder$ = this._apiCaller.getOrder(this.orderId).pipe(
                    tap((order) => {
                        this.order = order;
                        this.canChange = (this.order.status === ORDERSTATUS.WAITING);
                        this._changeDetectorRef.markForCheck();
                    }),
                );
            }
            // Get products and Map Products details to the orderItem
            // TODO: improve timing with parallel call together with the order details
            myOrder$.pipe(
                map((order) => {
                    order.infoObject = order.infoObject.filter(comment => comment.isPublic || (comment.organizationId == this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId));
                    return order;
                }),
                switchMap(order => {
                    if (this.order.cart.products.length > 0) {
                        return forkJoin({
                            store: this._apiCaller.getStore(this.order.storeId).pipe(
                                tap((responseStore) => {
                                    this.shippingStore = responseStore;
                                    this._changeDetectorRef.markForCheck();
                                })
                            ),
                            organization: this._apiCaller.getOrganizations({ organizationId: order._referenceOrganizationId }).pipe(
                                switchMap((organization: Organization[]) => {
                                    if (organization[0]?.vatNumber) {
                                        return this._apiCaller.getClientInfo(organization[0]?.vatNumber).pipe(
                                            tap((clientInfo) => {
                                                this.legalAddress = BeautyciansUtils.legalAddressFromClientInfo(clientInfo);
                                                this.defaultPaymentTerms = clientInfo?.pagamento;
                                                if (clientInfo?.cod_pagamento && this.ID_PAGAMENTI_CONTRATTO.includes(parseInt(clientInfo?.cod_pagamento)))
                                                    this.paymentConditions.push({ id: 0, label: "Condizioni di pagamento da contratto" });
                                                else if (this.order.externalPaymentConditionsId==0 && this.canChange)
                                                    this.order.externalPaymentConditionsId = null;
                                                this._changeDetectorRef.markForCheck();
                                            })
                                        );
                                    } else {
                                        return of({});
                                    }
                                }),
                            ),
                        })
                    } else {
                        return of({});
                    }
                })
            ).subscribe({
                next: (res) => {
                    if (!this.order.storeId)
                        this.shippingStore = this.legalAddress;
                    this.order = Object.assign({}, this.order);
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                },
                error: (msg) => {
                    console.log('Error while getting products: ', msg);
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                }
            });
        });


        const genericTranslations = ['warning', 'cancel', 'confirm', 'close', 'send', 'error', 'insert', 'internal', 'public', 'delete', 'autoSaveOK'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        const orderTranslations = ['plural', 'singular', 'no', 'search', 'addNew', 'copyToNew', 'noSearchResults', 'total', 'shippingTo', 'errorWhilePlacing', 'confirmCancel', 'statusUpdated', 'downloadInXls', 'defaultPaymentTerms'];
        orderTranslations.forEach((translation) => {
            this.translateStrings['order'][translation] = this._translate.translate('orders.' + translation);
        });

        const orderDetailsTranslations = ['no', 'gotobuy', 'search', 'addNew', 'send', 'noSearchResults', 'confirm', 'proceed', 'executeOrder', 'executedOrder', 'errorWhileExecuting', 'fillPaymentTerms', 'fillPaymentTermsTitle'];
        orderDetailsTranslations.forEach((translation) => {
            this.translateStrings['orderDetail'][translation] = this._translate.translate('orderDetails.' + translation);
        });

        const productsTranslations = ['name', 'unitPrice', 'price', 'discount', 'quantity', 'category', 'dimension', 'websiteRef', 'imgUrl', 'quantity', 'generic'];
        productsTranslations.forEach((translation) => {
            this.translateStrings['product'][translation] = this._translate.translate('products.' + translation);
        });

    }

    /**
 * Open tags panel
 */
    openStatusPanel(): void {
        // Create the overlay
        this._statusPanelOverlayRef = this._overlay.create({
            backdropClass: '',
            hasBackdrop: true,
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._statusPanelOrigin.nativeElement)
                .withFlexibleDimensions(true)
                .withViewportMargin(64)
                .withLockedPosition(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    }
                ])
        });

        // Subscribe to the attachments observable
        this._statusPanelOverlayRef.attachments().subscribe(() => {

            // Add a class to the origin
            this._renderer2.addClass(this._statusPanelOrigin.nativeElement, 'panel-opened');

            // Focus to the search input once the overlay has been attached
            this._statusPanelOverlayRef.overlayElement.querySelector('input').focus();
        });

        // Create a portal from the template
        const templatePortal = new TemplatePortal(this._statusPanel, this._viewContainerRef);

        // Attach the portal to the overlay
        this._statusPanelOverlayRef.attach(templatePortal);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        if (this._statusPanelOverlayRef) {
            this._statusPanelOverlayRef.dispose();
        }
    }

    addComment(): void {
        if (this.commentInput.nativeElement.value == "")
            return;
        const order = { ...this.order };
        let comment: OrderInfo = {
            personId: this._authService.getCurrentLoggedUser().personId,
            organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId,
            date: new Date().getTime(),
            comment: this.commentInput.nativeElement.value,
            isPublic: this.contextToggle.checked,
        };
        this._apiCaller.addOrderComment(order, comment).subscribe({
            next: (orderResponse) => {
                this.order.infoObject = orderResponse.infoObject;
                this.order = Object.assign({}, this.order);
                this.addCommentForm.resetForm();
                this._changeDetectorRef.markForCheck();
            }
        })
    }

    deleteComment(comment: OrderInfo): void {
        const dialogRef = this._fuseConfirmationService.open({
            "title": this.translateStrings.generic.confirm,
            "message": this.translateStrings.order.confirmCancel + ": \"" + comment.comment + "\" ?",
            "icon": {
                "show": true,
                "name": "delete",
                "color": "primary"
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": this.translateStrings['generic']['confirm'],
                    "color": "primary"
                },
                "cancel": {
                    "show": true,
                    "label": this.translateStrings['generic']['cancel']
                }
            },
            "dismissible": true
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result == "confirmed") {
                this._apiCaller.deleteOrderComment(this.order, comment.uuid).subscribe({
                    next: (orderResponse) => {
                        this.order.infoObject = orderResponse.infoObject;
                        this.order = Object.assign({}, this.order);
                        this._changeDetectorRef.markForCheck();
                    }
                })
            }
        });
    }

    addProductToCart(productId: string): void {
        this.currentOrder = this._cartService.addProductToCart(productId);
    }

    removeProductFromCart(productId: string): void {
        this.currentOrder = this._cartService.removeProductFromCart(productId);
    }

    getStatusColor(status: string): string {
        return OrderStatus.getStatusColor(status);
    }

    getStatusIcon(status: string): string {
        return OrderStatus.getStatusIcon(status);
    }

    updateOrder(order$: Order): void {
        order$.cart.products = order$.cart.products.filter(item => item.quantity > 0);

        if (!order$.orderId) {
            const cart = {};
            order$.cart.products.forEach((product) => {
                cart[product.productId] = product.quantity;
            });
            this._cartService.setCart(cart);
        }
        this._apiCaller.updateOrder(order$).subscribe({
            next: (response) => {
                this._snackBar.open(this.translateStrings['generic']['autoSaveOK'], null, {
                    duration: 1500,
                    horizontalPosition: "left",
                    verticalPosition: "bottom"
                });
            },
            error: (error) => {
                console.error(error);
                this._fuseConfirmationService.open(
                    BeautyciansUtils.getErrorDialogConfig('Something went wrong:' + error.error, 'Error', 'OK')
                );
            }
        });
    }

    proceedWithOrder(): void {
        const config = {
            "title": this.translateStrings['orderDetail']['confirm'],
            "message": "",
            "icon": {
                "show": true,
                "name": "send",
                "color": "primary"
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": this.translateStrings['orderDetail']['send'],
                    "color": "primary"
                },
                "cancel": {
                    "show": true,
                    "label": this.translateStrings['generic']['cancel'],
                }
            },
            "dismissible": true
        }

        const customDialog = this.dialog.open(OrderConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !config.dismissible,
            data: config,
            panelClass: 'custom-confirmation-dialog-panel'
        })
        customDialog.componentInstance.order = this.order;

        customDialog.afterClosed().subscribe((result) => {
            // if (result.message === 'confirm') {
            //     this.order.storeId = result.store.storeId;
            //     this.sendOrder();
            // }
        });

    }

    executeOrder(): void {
        if (this.order.externalPaymentConditionsId == null) {
            this._fuseConfirmationService.open(
                BeautyciansUtils.getErrorDialogConfig(this.translateStrings.orderDetail.fillPaymentTerms, this.translateStrings.orderDetail.fillPaymentTermsTitle, 'OK')
            );
            return;
        }
        this.isSpecificLoading['executing'] = true;
        this._apiCaller.sendOrderToMexal(this.orderId).pipe(
            tap((response) => {
                this.order.status = ORDERSTATUS.PROCESSING;
                this.updateOrder(this.order);
                this.canChange = (this.order.status === ORDERSTATUS.WAITING);
                this.order = _.cloneDeep(this.order);
            }),
            mergeMap((order) => {
                return this._apiCaller.sendOrderExecutionEmail(order)
              })
        ).subscribe({
            next: (res) => {
                this._snackBar.open(this.translateStrings.orderDetail.executedOrder, 'OK', {
                    horizontalPosition: 'start',
                    verticalPosition: 'bottom',
                    duration: 2000,
                });
                this.isSpecificLoading['executing'] = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (error) => {
                this.isSpecificLoading['executing'] = false;
                this._fuseConfirmationService.open(
                    BeautyciansUtils.getErrorDialogConfig(this.translateStrings.orderDetail.errorWhileExecuting, 'Error', 'OK')
                );
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    donwloadXls() {
        if (!this.order)
            return;
        const filename = 'order_' + this.order.id + '_' + this.order.name + '.xlsx';
        // ID must be the one provided in order-table component
        XlsUtils.exportTableToXls(filename, "excel-table");
        // let dataMatrix = [
        //     [this.translateStrings.product.name, this.translateStrings.product.dimension, this.translateStrings.product.quantity, this.translateStrings.product.unitPrice, this.translateStrings.product.price],
        // ];
        // this.order.items.forEach(element => {
        //     dataMatrix.push([element.name, element.])
        // });
        // const formula:string = 'SUM(E2:E' + (this.order.items.length + 2).toString() + ')';
        // dataMatrix.push([,,,,{f:formula}])
        // XlsUtils.exportMatrixToXls(filename, dataMatrix);
    }
    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
